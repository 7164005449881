import axios from "axios"
import { useState, useContext, useEffect } from "react"
import { Post } from "./Post"
import { UserContext } from "../providers/userProvider"
import { Container, Button, TextField, Box, Backdrop, CircularProgress } from "@mui/material"

export const Posts = () => {

    const [posts, setPosts]  = useState([])
    const [originalPosts, setOriginalPosts] = useState([]) 
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const {axiosConfig, app_id} = useContext(UserContext)

    const view = "Posts"

    useEffect(()=>{
        getPosts()
    },[])

    const getPosts = async () => {
        setLoading(true)
        let url = "https://api.airtable.com/v0/" + app_id + "/" + view
  
        try {
          
          let res = await axios.get(url,axiosConfig)
          let data = res.data.records
          console.log(data)
        //   let sortedData = data.sort(compare)
          let sortedData = data.sort((a,b)=> new Date(b.createdTime) - new Date(a.createdTime))

          console.log(sortedData)
          setPosts(sortedData)
          setOriginalPosts(sortedData)
          setLoading(false)
        } catch (error) {
          
        }
        
      }

      const getSinglePost = () => {

      }

      const createPost = () => {

      }

      const editPost = () => {

      }

      const deletePost = ()=>{

      }

      const searchBlog = (e) => {
        let searchText = e.target.value
        setSearch(e.target.value)
        let filteredPosts = originalPosts.filter((post) => {
            let postText = "".concat(post.fields.title,post.fields.content,post.fields.fullName).toLowerCase()
            return postText.includes(searchText.toLowerCase())
        })
        setPosts(filteredPosts)
      }
      
      const dateFilters = () => {

        const now = new Date();

        // Set time to the beginning of the day
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

        // Set time to the end of the day
        const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

        const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

        const filterDate = (start, end) =>{

            let filteredPosts = originalPosts.filter((post) => {

                return new Date(post.createdTime) > start && new Date(post.createdTime) < end
            })
            setPosts(filteredPosts)
            
        }

        const handleClearFilter = () => {
            setPosts(originalPosts)
            setSearch("")
        }


          return(
              <Box sx={{display: "flex", flexDirection: {xs: "row", md:"column"}, justifyContent: "space-between", marginLeft: {xs: "0px", md:"25px"}, width: {md:"100%"}}}>
                <Button sx={{marginTop: {md:"10px"}}} size="small" variant="contained" color="primary" onClick={()=>{handleClearFilter()}}>
                    All
                </Button>
                <Button sx={{marginTop: {md:"10px"}}} size="small" variant="contained" color="primary" onClick={()=>{filterDate(startOfToday,endOfToday)}}>
                    Todays Posts
                </Button>
                <Button sx={{marginTop: {md:"10px"}}} size="small" variant="contained" color="primary" onClick={()=>{filterDate(oneWeekAgo,endOfToday)}}>
                    This Weeks Posts
                </Button>
              </Box>
          )
      }

    return(
        <>
            <Container sx={{display: "flex", flexDirection: {xs: "column", md:"row-reverse"}}}>
                <Box>
                    <TextField color="primary" variant ="outlined" sx={{marginTop: "25px", marginLeft:{md:"25px"},marginBottom:"10px", width: "100%", background:"white", color: "black"}} onChange={searchBlog} value={search} id="outlined-basic" label="Search" placeholder="Search"/>
                    {dateFilters()}
                </Box>
                <Box>
                    {posts.map(post => {
                        return(
                            <Post key={post.id} title={post.fields.title} content={post.fields.content} photos={post.fields.photos} author={post.fields.fullName} date={post.fields.date}/>
                        )
                    })}
                </Box>
            </Container>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}