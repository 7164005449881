import React from "react";
import { useState } from "react";
import Axios from "axios";

export const UserContext = React.createContext({});

const UserProvider = (props) => {
  const personal_access_token =
    "patfsTDhIJTa4vCGx.a92036f764d68e2c54e2ee34c273f41187c2c84c74696d0ef26613bd23db6eb7";
  const app_id = "apprU3voGMRdSdG7c";
  const axiosConfig = {
    headers: {
      Authorization: "Bearer " + personal_access_token,
      "Content-Type": "application/json",
    },
  };

  const [user, setUser] = useState({
    userId: null,
    phoneNumber: null,
    fullName: null,
  });

  const getUser = async (userName) => {
    let table = "Users";
    let url =
      "https://api.airtable.com/v0/" +
      app_id +
      "/" +
      table +
      "?filterByFormula=(%7BfullName%7D+%3D+%22" +
      userName +
      "%22)";

    try {
      let res = await Axios.get(url, axiosConfig);
      setUser({
        userName: res.data.records[0].fields.fullName,
        userId: res.data.records[0].id,
        phoneNumber: res.data.records[0].fields.phoneNumber,
      });
      console.log(res.data.records[0].fields.fullName);
      console.log(res.data.records[0].id);
      console.log(res.data.records[0].fields.phoneNumber);

      localStorage.setItem("fullName", res.data.records[0].fields.fullName);
    } catch (error) {}
  };

  const handleLogout = () => {
    setUser({ fullName: null, userId: null, phoneNumber: null });
    localStorage.clear();
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getUser,
        handleLogout,
        axiosConfig,
        app_id,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
