import { Posts } from "./Posts"
import { Box } from "@mui/material"

export const Home = () => {
  return (
    <Box >
      <Posts/>
      <Box sx={{height:"10vh"}}>

      </Box>
    </Box>
  )
}