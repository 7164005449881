import { Card, CardContent, CardHeader, Typography, CardMedia } from "@mui/material"

export const Post = (props) => {

  const {title, content, photos, author} = props

    return (
      <Card sx={{marginTop: "10px"}}>
          <CardHeader title={title}/>
          { photos ? photos.map(photo => <CardMedia key={photo.id} sx={{marginTop: "10px"}} component="img" image={photo.url} />) : "" }
          <CardContent>
            <Typography variant="body1">
              {content}
            </Typography>
            
            <Typography variant ="caption">
              Author: {author}
            </Typography>
        </CardContent>
      </Card>
    )
}