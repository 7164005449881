import { Login } from "./postComponents/Login";
import { Route, Routes } from "react-router";
import NavBar from "./postComponents/NavBar";
import FetchUser from "./postComponents/FetchUser";
import { Home } from "./postComponents/Home";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from "@mui/material";

const theme = createTheme({
  palette: {
    primary : {
      main: "#FFFFFF"
    },
    secondary : {
      main: "#000000"
    }
  }
})

function App() {
  return(
    <ThemeProvider theme={theme}>
    <NavBar />
    <Box sx={{
      backgroundImage:'url(https://dl.airtable.com/.attachments/96c5f0ba84c6cbc07dc5d8dd44a23899/7b72b113/cow-spots-love-my-barnyard-transparent.png)', 
      minHeight: '100vh', 
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed"}}>
    <FetchUser>
        <Routes>
          <Route exact path='/login' element={<Login/>}/>
          <Route exact path='/' element={<Home/>}/>
        </Routes>
    </FetchUser>
    </Box>
  </ThemeProvider>
  )
}

export default App;
