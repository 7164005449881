import { Button, AppBar, Toolbar, Typography, Box, Drawer, Container } from "@mui/material"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../providers/userProvider"
import { useState } from "react"
import PostAddIcon from '@mui/icons-material/PostAdd';

const NavBar = ()=>{
    
    const {user, handleLogout} = useContext(UserContext)
    const [open, setOpen] = useState(false)

    const navigate = useNavigate()

    console.log(user)
    const handleButtonClick = (path, action)=> {
        if(action === "logout"){
            handleLogout()
        }
        navigate(path)
    }

    const renderUserName = () => {
        return(
            <h4 style={{textAlign: "center"}}>Welcome {user.userName}!</h4>
        )
    }

    const renderHomeButton = () => {
        return(
            <Button sx={{margin: 2}} variant="contained" size="small" onClick = {()=>handleButtonClick("/")}>
                Home
            </Button>
        )
    }

    const loggedInToolbar = () => {
        console.log(user)
        return(
            <div style={{display: "flex", flexDirection: "row"}}>
                {renderUserName()}
                <Button sx={{margin: 2}} variant="contained" size="small" onClick = {()=>handleButtonClick("/login", "logout")}>
                    Logout
                </Button>
            </div>
        )
    }

    const loggedOutToolbar = () => {
        return(
            <Box sx={{display:"flex", flexdirection: "row"}}>
                <Button sx={{margin: 2}} variant="contained" size="small" onClick = {()=>navigate("/")}>
                    Home
                </Button>
                <Button sx={{margin: 2}} variant="contained" size="small" onClick = {()=>setOpen(true)}>
                    <PostAddIcon/>
                </Button>
                {/* //WHEN LOGIN LOGIC IS COMPLETE UNCOMMENT THIS */}
                {/* <Button sx={{margin: 2}} variant="contained" size="small" onClick = {()=>handleButtonClick("/login", "login")}>
                    Login
                </Button> */}
            </Box>
        )
    }

    const drawerContents = () =>{
        return(
            <Container>
                <h2>How do I add a post?</h2>
                <Typography variant="body1">
                    Glad you asked. <br/><br/>

                    Simply text your blog entry to 1 (208) 670-8375. Refresh the blog and wa-la! You've just posted your first blog entry! <br/><br/>

                    You are able to post text and photos to the blog. It's recommend that you only post 1-2 photos for every entry to keep the blog legible.<br/><br/>

                    When you send a photo, it's important to note that you send the picture and text in <strong>the same message</strong>. 
                    If you don't your blog post will get split into 2 different posts. One for your picture and one for your message. <br/><br/>

                    Right now I don't have the functionality built in to edit or delete posts by users. If you would like a post edited or deleted, just call me and I'll take care of it for you.<br/><br/>

                    Enjoy! 
                </Typography>

                <Button sx={{marginTop: "20px"}} onClick={()=>setOpen(false)}>
                    Close
                </Button>
            </Container>
        )
    }

        return(
        <>
            <AppBar position="sticky" color="primary" sx={{ top: 0, bottom: 'auto'}}>
                <Toolbar sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Typography>Whitesides Family Blog</Typography>
                    {/* {user.userId ? loggedInToolbar() : loggedOutToolbar()} */}
                    {loggedOutToolbar()}
                </Toolbar>
            </AppBar>
            <Drawer
            anchor={"top"}
            open={open}
            onClose={()=>setOpen(false)}
        >
            {drawerContents()}
        </Drawer>
      </>
        )

  
}

export default NavBar